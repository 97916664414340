import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import FivePointRatingPreview from '../../../components/authoring/questionPreview/FivePointRatingPreview';
import { IApplicationRootReducerState } from "../../../configureStore";
import { IQuestionEntity } from '../../../contracts/models';
import { GetFivePointSiufFocusFromValue } from '../../../utils/dataUtils';

type Props = {
    questionModel: IQuestionEntity;
};

const mapStateToProps = (state: IApplicationRootReducerState, ownProps: Props) => ({
    questionStructure: ownProps.questionModel.QuestionStructure,
    questionText: ownProps.questionModel.QuestionText,
    questionFocus: GetFivePointSiufFocusFromValue(ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).AnswerText, ownProps.questionModel.Answers.find(a => a.AnswerIndex === 4).AnswerText, ownProps.questionModel.QuestionStructure),
    isNpsQuestion: ownProps.questionModel.IsNpsQuestion,
    minAnchor: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0).AnswerText,
    maxAnchor: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 4).AnswerText,
    ratings1FollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 0)?.FollowUpQuestion?.QuestionText ?? "",
    ratings2FollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 1)?.FollowUpQuestion?.QuestionText ?? "",
    ratings3FollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 2)?.FollowUpQuestion?.QuestionText ?? "",
    ratings4FollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 3)?.FollowUpQuestion?.QuestionText ?? "",
    ratings5FollowupQuestion: ownProps.questionModel.Answers.find(a => a.AnswerIndex === 4)?.FollowUpQuestion?.QuestionText ?? ""
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: Props) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FivePointRatingPreview);
