import * as React from 'react';
import { Checkbox, Form, Icon, Label, Segment } from 'semantic-ui-react';
import CheckboxQuestionView from '../../../containers/authoring/questionDetails/CheckboxQuestionView';
import FivePointRatingQuestionView from '../../../containers/authoring/questionDetails/FivePointRatingQuestionView';
import FreeformTextQuestionView from '../../../containers/authoring/questionDetails/FreeformTextQuestionView';
import LikertQuestionView from '../../../containers/authoring/questionDetails/LikertQuestionView';
import ThreePointRatingQuestionView from '../../../containers/authoring/questionDetails/ThreePointRatingQuestionView';
import YesNoQuestionView from '../../../containers/authoring/questionDetails/YesNoQuestionView';
import { IQuestionEntity, SiufQuestionStructure, SiufQuestionType } from '../../../contracts/models';
import { ISectionUiState } from '../../../contracts/states';
import { GetSupportedSiufQuestionTypes } from '../../../utils';
import '../../../siufAuthUx.css';

type Props = {
    questionModel: IQuestionEntity;
    questionIndex: number;
    pageIndex: number;
    uiState: ISectionUiState;
    canDelete: boolean;
    onUpdateQuestionType: (pageIndex: number, questionIndex: number, questionType: SiufQuestionType) => void;
    onRemoveQuestion?: (pageIndex: number) => void;
    onUpdateNpsQuestion: (checked: boolean) => void;
};

const Question: React.FC<Props> = props => {

    const options = GetSupportedSiufQuestionTypes().map(qt => {
        return { key: qt.siufQuestionType, text: qt.displayName, value: qt.siufQuestionType };
    });

    let questionView: JSX.Element;
    switch (props.questionModel.QuestionType) {
        case SiufQuestionType.FivePoint:
            questionView = <FivePointRatingQuestionView questionModel={ props.questionModel } isReadOnly={ props.uiState.isReadOnly } pageIndex={ props.pageIndex }/>;
            break;
        case SiufQuestionType.ThreePoint:
            questionView = <ThreePointRatingQuestionView questionModel={ props.questionModel } isReadOnly={ props.uiState.isReadOnly } pageIndex={ props.pageIndex }/>;
            break;
        case SiufQuestionType.YesNo:
            questionView = <YesNoQuestionView questionModel={ props.questionModel } isReadOnly={ props.uiState.isReadOnly } pageIndex={ props.pageIndex }/>;
            break;
        case SiufQuestionType.Text:
            questionView = <FreeformTextQuestionView questionModel={ props.questionModel } isReadOnly={ props.uiState.isReadOnly } pageIndex={ props.pageIndex }/>;
            break;
        case SiufQuestionType.CheckBox:
            questionView = <CheckboxQuestionView questionModel={ props.questionModel } isReadOnly={ props.uiState.isReadOnly } pageIndex={ props.pageIndex }/>;
            break;
        case SiufQuestionType.Likert:
            questionView = <LikertQuestionView questionModel={ props.questionModel } isReadOnly={ props.uiState.isReadOnly } pageIndex={ props.pageIndex }/>;
            break;
        default:
            questionView = <div>Question type not supported</div>;
    }
    return (
        <Segment
            basic={ (props.questionModel.QuestionStructure !== SiufQuestionStructure.MultiQuestion) }
            raised={ (props.questionModel.QuestionStructure === SiufQuestionStructure.MultiQuestion) }>
            <div className='flex-div'>
                {
                    props.questionModel.QuestionStructure === SiufQuestionStructure.MultiQuestion &&
                    <Label as='a' color='blue' ribbon> Question { props.questionIndex + 1 } </Label>
                }
                {
                    props.questionModel.QuestionStructure === SiufQuestionStructure.MultiQuestion && props.canDelete &&
                    <span>
                        <Icon name="trash alternate outline" color='red' onClick={ () => props.onRemoveQuestion(props.pageIndex) } />
                    </span>
                }
                {
                    (props.questionModel.QuestionType === SiufQuestionType.FivePoint || 
                        (props.questionModel.QuestionType === SiufQuestionType.Likert && props.questionModel.Answers?.length == 5 && 
                            props.questionModel.ScaleItems?.length == 1)) &&
                    <span className='aligned-checkbox'>
                        <Checkbox toggle label={"Is NPS Question"} checked={ props.questionModel.IsNpsQuestion }
                        disabled={ props.uiState.isReadOnly } onChange={(e, data) => props.onUpdateNpsQuestion(data.checked)}/>
                    </span>
                }
            </div>
            <Form>
                {
                    props.uiState.isReadOnly
                        ?
                        <Form.Input fluid value={ options.find(o => o.value === props.questionModel.QuestionType)?.text } label='Question Type'
                            options={ options }
                            readOnly={ true }
                            placeholder='Select Question Type' required
                            tabIndex='5'
                        />
                        :                        
                        <Form.Select fluid value={ props.questionModel.QuestionType }
                            readOnly={ props.uiState.isReadOnly }
                            label='Question Type' options={ options }
                            placeholder='Select Question Type' required
                            title='Question Type'
                            tabIndex='5'
                            onChange={ (event, data) => props.onUpdateQuestionType(props.pageIndex, props.questionIndex, data.value as number) }
                        />
                }
                { questionView }
            </Form>
        </Segment>
    );
};

export default Question;